import {
  gsap
} from "gsap";
import {
  isDesktop
} from './helpers';

/*
 * CLOSE AND OPEN MOBILE NAV
 */
const toggleMenu = document.querySelector('.toggle_menu');
if ( toggleMenu ) {
  toggleMenu.addEventListener('click', (e) => {
    if (toggleMenu.classList.contains('is-open')) {
      toggleMenu.classList.remove('is-open');

      /** 
       * Closing the menu
       */
      let openSubNavs = document.querySelectorAll('.nav__dropdown__slidemobile');
      if (openSubNavs) {
        [...openSubNavs].forEach( (nav) => {
          nav.remove();
        })
      }
      document.querySelector('body').classList.remove('mobile-menu-open');
      gsap.to('.nav--open', {
        opacity: 0,
        duration: .3,
        onComplete: () => {
          document.querySelector('nav.nav').classList.remove('nav--open');
          gsap.to('nav.nav', {
            opacity: 1,
            duration: .3
          });
        }
      });

    } else {
      /**
       * Add topbar menu to mobile menu
       * */
      const primarymenu = document.querySelector('#menu-primary');
      if ( !primarymenu.classList.contains( 'cloned-is-added') ) { 
        const topbarmenu = document.querySelector('#menu-topbar');
        let clonedTopbarmenu = topbarmenu.cloneNode(true);
        clonedTopbarmenu.style.display = 'flex !important';
        clonedTopbarmenu.classList.remove( 'justify-content-end' );
        clonedTopbarmenu.classList.remove( 'flex-row' );
        clonedTopbarmenu.classList.add( 'topbar_nav_cloned' );
        primarymenu.appendChild(clonedTopbarmenu);
        primarymenu.classList.add('cloned-is-added');
      }

      toggleMenu.classList.add('is-open');
      document.querySelector('body').classList.add('mobile-menu-open');
      document.querySelector('nav.nav').classList.add('nav--open');
      // gsap.fromTo('.nav--open', {
      //   opacity: 0,
      //   duration: .3
      // }, {
      //   opacity: 1,
      //   duration: .3
      // });
      // // var tl = new TimelineLite();
      // let tl = new gsap.timeline();
      // tl.add("stagger", "+=0.5");
      // tl.staggerFrom('.nav__item__parent', .2, {
      //   opacity: 0,
      //   x: 50
      // }, .1, "stagger")
    }
  });
}

function handleMobileClicks(e) {
  if ( !isDesktop() ) {
    /**
     * Primary
     */
    if (e.target.matches('a.parent_link_primary')) {
      e.preventDefault();
      let alink = e.target
      if (alink.classList.contains('is-active-submenu')) {
        alink.classList.remove('is-active-submenu')
      } else {
        alink.classList.add('is-active-submenu')
      }
    }

    /*
    * SLIDEPANEL ON MOBILE AND TABLET FIRST PANEL
    */
    if (e.target.matches('a.parent_link')) {
      e.preventDefault();
      const listElement = e.target.parentNode;
      let mainDropdown = listElement.querySelector('.nav__dropdown');
      let clonedMainDropdown = mainDropdown.cloneNode( true );
      let openNav = document.querySelector('.nav.nav--open');
      document.querySelector( 'body' ).classList.add( 'sub-sub-open' );
      if (clonedMainDropdown !== null) {
        openNav.prepend(clonedMainDropdown);
        setTimeout(function () {
          clonedMainDropdown.classList.add('nav__dropdown__slidemobile');
        }, 300);
      }
    }

    /*
    * SLIDEPANEL ON MOBILE AND TABLET SECOND PANEL
    */
    if (e.target.matches('.category_link')) {
      e.preventDefault();

      var categoryLink = e.target;
      var parentElement = categoryLink.parentNode;
      var categoryDropdown = parentElement.querySelector('.inside-dropdown');
      var actualDropdown = categoryDropdown.querySelector('.nav__items__second__dropdown');
      var allCategories = parentElement.parentNode;
      var container = allCategories.parentNode;

      if (actualDropdown.querySelector('#back_to_categories') === false || actualDropdown.querySelector('#back_to_categories') === null) {
        var backBtn = document.createElement('li');
        backBtn.innerText = 'Terug naar categorieën';
        backBtn.setAttribute('id', 'back_to_categories');
        actualDropdown.prepend(backBtn);
      }

      container.classList.add('categorie-open');
      allCategories.classList.add('slide-left');
      categoryDropdown.classList.add('slide-left');

    }

    if (e.target.matches('#back_to_categories')) {
      var dropdown = e.target.parentNode;
      var categorie = dropdown.parentNode;
      var listElement = categorie.parentNode;
      var allCategories = listElement.parentNode;
      var container = allCategories.parentNode;

      categorie.classList.remove('slide-left');
      allCategories.classList.remove('slide-left');
      container.classList.remove('categorie-open');
    }

    // Close dropdown
    if (e.target.matches('#close__dropdown')) {
      let openNav = document.querySelector('.nav.nav--open');
      let dropdownMenu = openNav.firstElementChild;

      // Hide menu
      dropdownMenu.classList.remove('nav__dropdown__slidemobile');
      document.querySelector( 'body' ).classList.remove( 'sub-sub-open' );


      // Set back to list element
      setTimeout(function () {
        let indexNumber = dropdownMenu.dataset.index;
        let primaryMenu = document.querySelector('#menu-primary');
        let listElement = primaryMenu.querySelector('li.nav__item[data-index="' + indexNumber + '"]');

        listElement.append(dropdownMenu);
      }, 300);
    }


  }

}
document.addEventListener('click', handleMobileClicks);
// document.addEventListener( 'touchmove', handleMobileClicks);




/*
 * DROPDOWNS FOR DESKTOP
 */
if (isDesktop()) {
  document.addEventListener('mouseover', (e) => {

    // Open dropdown for primary menu
    if (e.target.matches('#menu-primary li.nav__item__parent.menu-item-has-children a.parent_link')) {
      const dropdowns = document.querySelectorAll('.nav__dropdown');
      [].forEach.call(dropdowns, (element) => {
        // element.dataset.index

        // Show active dropdown
        if (!e.target.parentNode.lastChild.classList.contains('nav__dropdown__visible')) e.target.parentNode.lastChild.classList.add('nav__dropdown__visible');

        // Active other dropdowns if not already active: because of sliding animation
        if (!element.classList.contains('nav__dropdown__visible')) element.classList.add('nav__dropdown__visible');

        // Make current dropdown active
        if (e.target.parentNode.lastChild.classList.contains('nav__dropdown__inactive')) e.target.parentNode.lastChild.classList.remove('nav__dropdown__inactive');

        if (e.target.parentNode.lastChild !== element) {
          if (element.classList.contains('nav__dropdown__visible')) {

            // Set other dropdowns on inactive
            element.classList.add('nav__dropdown__inactive');

            // Hide content of other dropdowns
            [].forEach.call(element.children, (childElement) => {
              let currentIndex = e.target.parentNode.dataset.index;
              let elementIndex = element.parentNode.dataset.index;
              if (currentIndex > elementIndex) {
                childElement.classList.add('nav__items__dropdown__hiddentoleft');
              } else {
                childElement.classList.add('nav__items__dropdown__hiddentoright');
              }
              // Hide content
              childElement.classList.remove('nav__items__dropdown__visible');
            });
          }
        } else {
          // Show content of active dropdown 
          [].forEach.call(e.target.parentNode.lastChild.children, (childTargetElement) => {
            // Show content
            childTargetElement.classList.add('nav__items__dropdown__visible');
            if (childTargetElement.classList.contains('nav__items__dropdown__hiddentoright')) childTargetElement.classList.remove('nav__items__dropdown__hiddentoright');
            if (childTargetElement.classList.contains('nav__items__dropdown__hiddentoleft')) childTargetElement.classList.remove('nav__items__dropdown__hiddentoleft');
          });
        }
      });
    }
    if ((!e.target.matches('li.menu-item-has-children a') &&
        !e.target.matches('.nav') &&
        !e.target.matches('nav') &&
        !e.target.matches('.loose_link.text-black.fw-bold') &&
        !e.target.matches('li.menu-item-has-children') &&
        !e.target.matches('li.nav__item__parent') &&
        !e.target.matches('li.nav__item__child') &&
        !e.target.matches('.nav__dropdown') &&
        !e.target.matches('.inside_dropdown') &&
        !e.target.matches('.nav__items') &&
        !e.target.matches('.nav__items__dropdown') &&
        !e.target.matches('.nav__dropdown__close-btn') &&
        !e.target.matches('.nav__item') &&
        !e.target.matches('.nav__item span') &&
        !e.target.matches('.nav__item a') &&
        !e.target.matches('.nav__items__second__dropdown') &&
        !e.target.matches('.job-counter') &&
        !e.target.matches('.nav__dropdown__cta') &&
        !e.target.matches('.nav__dropdown__cta__wrapper') &&
        !e.target.matches('.ms-2.material-icons.chevron_right')
      ) || e.target.matches('.loose_link')) {
      const dropdowns = document.querySelectorAll('#menu-primary .nav__dropdown.nav__dropdown__visible');
      [].forEach.call(dropdowns, function (element) {
        element.classList.remove('nav__dropdown__visible');
        element.classList.remove('nav__dropdown__inactive');
      });
    }

    // Open dropdown for topbar menu
    if (e.target.matches('#menu-topbar li.nav__item__parent.menu-item-has-children a.parent_link')) {
      const dropdowns = document.querySelectorAll('.nav__dropdown');
      [].forEach.call(dropdowns, (element) => {
        // element.dataset.index

        // Show active dropdown
        if (!e.target.parentNode.lastChild.classList.contains('nav__dropdown__visible')) e.target.parentNode.lastChild.classList.add('nav__dropdown__visible');

        // Active other dropdowns if not already active: because of sliding animation
        if (!element.classList.contains('nav__dropdown__visible')) element.classList.add('nav__dropdown__visible');

        // Make current dropdown active
        if (e.target.parentNode.lastChild.classList.contains('nav__dropdown__inactive')) e.target.parentNode.lastChild.classList.remove('nav__dropdown__inactive');

        if (e.target.parentNode.lastChild !== element) {
          if (element.classList.contains('nav__dropdown__visible')) {

            // Set other dropdowns on inactive
            element.classList.add('nav__dropdown__inactive');

            // Hide content of other dropdowns
            [].forEach.call(element.children, (childElement) => {
              let currentIndex = e.target.parentNode.dataset.index;
              let elementIndex = element.parentNode.dataset.index;
              if (currentIndex > elementIndex) {
                childElement.classList.add('nav__items__dropdown__hiddentoleft');
              } else {
                childElement.classList.add('nav__items__dropdown__hiddentoright');
              }
              // Hide content
              childElement.classList.remove('nav__items__dropdown__visible');
            });
          }
        } else {
          // Show content of active dropdown 
          [].forEach.call(e.target.parentNode.lastChild.children, (childTargetElement) => {
            // Show content
            childTargetElement.classList.add('nav__items__dropdown__visible');
            if (childTargetElement.classList.contains('nav__items__dropdown__hiddentoright')) childTargetElement.classList.remove('nav__items__dropdown__hiddentoright');
            if (childTargetElement.classList.contains('nav__items__dropdown__hiddentoleft')) childTargetElement.classList.remove('nav__items__dropdown__hiddentoleft');
          });
        }
      });
    }

    if (!e.target.classList.contains('no-close-sub-menu')) {
      const dropdowns = document.querySelectorAll('#menu-topbar .nav__dropdown.nav__dropdown__visible');
      [].forEach.call(dropdowns, function (element) {
        element.classList.remove('nav__dropdown__visible');
        element.classList.remove('nav__dropdown__inactive');
      });
    }
  });


  // Close the fly-out menu
  document.addEventListener('click', (e) => {
    // Open dropdown
    if (e.target.matches('.nav__dropdown__close-btn')) {
      const dropdowns = document.querySelectorAll('.nav__dropdown.nav__dropdown__visible');
      [].forEach.call(dropdowns, function (element) {
        element.classList.remove('nav__dropdown__visible');
      })
    }

    // Close dropdown on click
    if (e.target.matches('li.menu-item-has-children a') ||
      e.target.matches('.nav') ||
      e.target.matches('nav') ||
      e.target.matches('li.menu-item-has-children') ||
      e.target.matches('li.nav__item__parent') ||
      e.target.matches('li.nav__item__child') ||
      e.target.matches('.nav__dropdown') ||
      e.target.matches('.inside_dropdown') ||
      e.target.matches('.nav__items') ||
      e.target.matches('.nav__items__dropdown') ||
      e.target.matches('.nav__dropdown__close-btn') ||
      e.target.matches('.nav__item') ||
      e.target.matches('.nav__item span') ||
      e.target.matches('.nav__item a') ||
      e.target.matches('.nav__items__second__dropdown') ||
      e.target.matches('.job-counter')
    ) {
      const dropdowns = document.querySelectorAll('.nav__dropdown.nav__dropdown__visible');
      [].forEach.call(dropdowns, function (element) {
        element.classList.remove('nav__dropdown__visible');
        element.classList.remove('nav__dropdown__inactive');
      });
    }
  });
}

document.addEventListener('scroll', (e) => {
  const fullheaderNavigation = document.querySelector('#scrollingNav');
  const body = document.querySelector('body');
  if (!!fullheaderNavigation) {
    if (window.scrollY > 650) {
      !body.classList.contains('page-scrolled') ? body.classList.add('page-scrolled') : false;
      !fullheaderNavigation.classList.contains('is-scrolled') ? fullheaderNavigation.classList.add('is-scrolled') : false;
    } else {
      body.classList.contains('page-scrolled') ? body.classList.remove('page-scrolled') : false;
      fullheaderNavigation.classList.contains('is-scrolled') ? fullheaderNavigation.classList.remove('is-scrolled') : false;
    }
  }

  const normalHeader = document.querySelector('.section-navbar');
  if (!!normalHeader) {
    if (window.scrollY > 0) {
      !body.classList.contains('fixed-nav') ? body.classList.add('fixed-nav') : false;
      !normalHeader.classList.contains('is-scrolled') ? normalHeader.classList.add('is-scrolled') : false;
    } else {
      normalHeader.classList.contains('is-scrolled') ? normalHeader.classList.remove('is-scrolled') : false;
      body.classList.contains('fixed-nav') ? body.classList.remove('fixed-nav') : false;
    }
  }

  /** 
   * STAP Sidebar 
   * */
  let stapsidebar = document.querySelector('.academy-overzicht-sidebar');
  let productsgrid = document.querySelector('#products_grid');
  if ( stapsidebar && productsgrid ) {
    if (stapsidebar.getBoundingClientRect().top > productsgrid.getBoundingClientRect().bottom || stapsidebar.getBoundingClientRect().top - 200 < productsgrid.getBoundingClientRect().top) {
      stapsidebar.classList.remove( 'mobile--active' );
    } else {
      stapsidebar.classList.add( 'mobile--active' );
    }
  }

});