(function() {
   function animateValue(element, duration) {
      // assumes integer values for start and end
      let start = 0;

      let isCounted = false;

      const obj = element;
      const changeTextValue = obj.querySelector('span');

      if(!obj.dataset.value) return;

      const end = obj.dataset.value;

      const range = end - start;
      // no timer shorter than 50ms (not really visible any way)
      const minTimer = 50;
      // calc step time to show all interediate values
      let stepTime = Math.abs(Math.floor(duration / range));

      // never go below minTimer
      stepTime = Math.max(stepTime, minTimer);

      // get current time and calculate desired end time
      const startTime = new Date().getTime();
      const endTime = startTime + duration;
      let timer;

      function run() {
         const now = new Date().getTime();
         const remaining = Math.max((endTime - now) / duration, 0);
         const value = Math.round(end - (remaining * range));
         changeTextValue.textContent = value.toLocaleString('nl');
         if (value == end) {
            clearInterval(timer);
         }
      }

      timer = setInterval(run, stepTime);
      run();
   }

   const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0
   };

   const onChange = (entries, observer) => {
      
      
      entries.forEach(entry => {
         
         setTimeout( () => {
            entry.target.style.display = 'block';
         }, 200);

         if(entry.intersectionRatio > 0) {
            animateValue(entry.target, 2000);
            observer.unobserve(entry.target);
         }
      });
   };

   const observer = new IntersectionObserver(onChange, options);

   const targets = document.querySelectorAll('.js-counter');
   targets.forEach(target => {
      observer.observe(target);
   })

})();
