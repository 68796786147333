import { onVisibilityChange } from './helpers';
import { Modal, Collapse, Button } from 'bootstrap';



// Theme
import './cookie';
import './js-counter';
import './menu';
import './notifier';
import './recaptcha';
import './logos';
import './lazyload';
import './steljevoor';
import './cf7';

import '../../modules/campagnes/src/loadmore';

jQuery("select option:first").attr('disabled', 'disabled');

const elements = [
  '#partnerlogos',
  '#cases-swiper',
  '#aboutus_slideshow',
  '.tabs',
  '[data-aos]',
  '#testimonials',
  '.sc__activiteiten',
  '.fas',
  '.fa',
  '.fab',
  'body.dom-page',
  '.aboutus-page',
  '#werkwijze_slideshow',
  '.single-academy-wrapper',
  '.hubspot__content__grid',
  '#incompany_logos'
];

[].forEach.call(elements, (element) => {

  if (document.querySelector(element) !== null && document.querySelector(element) !== undefined) {

    const lazyloadHandler = onVisibilityChange(document.querySelector(element), function () {

      if (element === '#partnerlogos') {
        const PartnerLogos = import('./partnerlogos')
      }

      if (element === '#incompany_logos') {
        const PartnerLogos = import('./incompany_logos')
      }

      if (element === '#cases-swiper') {
        const CasesSwiper = import('./cases-swiper')
      }
      if (element === '#aboutus_slideshow') {
        const AboutSwiper = import('./about-us-swiper')
      }

      if (element === '.tabs') {
        const TabsSwiper = import('./tabs')
      }

      if ( element === '.sc__activiteiten') {
        const ActivityTabs = import('../../modules/campagnes/src/tabs')
      }

      if ( element === '#testimonials') {
        const ActivityTabs = import('./testimonials')
      }

      if ( element === 'body.dom-page') {
        const DomPage = import('./dom-page')
      }

      if ( element === '.aboutus-page' ) {
        const routeTimeline = import( './routetimeline' );
      }
      
      if ( element === '.single-academy-wrapper' ) {
        const academyTabs = import( './single-academy' );
      }

      if ( element === '.hubspot__content__grid') {
        const academyTabs = import( './klantervaring-tabs' ); 
      }

      if ( element === '.fas' || element === '.fa' || element === '.fab') {
        const SolidFA = import( '@fortawesome/fontawesome-free/js/solid' );
        const Regular = import( '@fortawesome/fontawesome-free/js/regular' );
        const FontAwesome = import( '@fortawesome/fontawesome-free/js/fontawesome' );
      }

      // Example library with init:
      if (element === '[data-aos]' || element === '.aos-element') {
        const AosCSS = import ( 'aos/dist/aos.css' );
        const AOS = import('aos').then(AOS => {
          if (!document.querySelector('body').classList.contains('dom-page')) {
            AOS.init();
          }
        });
      }

      if ( element === '#werkwijze_slideshow' ) {
        const WerkWijzeSlideshow = import('./onze_werkwijze' );
      }

    })

    if (window.addEventListener) {
      addEventListener('DOMContentLoaded', lazyloadHandler, false);
      addEventListener('load', lazyloadHandler, false);
      addEventListener('scroll', lazyloadHandler, false);
      addEventListener('resize', lazyloadHandler, false);
    } else if (window.attachEvent) {
      attachEvent('onDOMContentLoaded', lazyloadHandler); // Internet Explorer 9+ :(
      attachEvent('onload', lazyloadHandler);
      attachEvent('onscroll', lazyloadHandler);
      attachEvent('onresize', lazyloadHandler);
    }
  }
});


const breakpointMobile = window.matchMedia( '(max-width: 768px)' );

const breakPointchecker = function() {

  const footerCollapses = document.querySelectorAll(".footer--items--item--title");
  
  if ( breakpointMobile.matches ) {

    footerCollapses.forEach( (col) => {
      col.setAttribute( 'data-bs-toggle', 'collapse' );
      col.style.pointerEvents = 'auto';
    });

    return;

  } else if ( !breakpointMobile.matches ) {

    footerCollapses.forEach( (col) => {
      col.removeAttribute('data-bs-toggle');
      col.style.pointerEvents = 'none';
    });

  }
}

breakpointMobile.addEventListener( 'change', () => {
  return breakPointchecker();
});

breakPointchecker();