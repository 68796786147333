jQuery(window).on( 'load', function(){
  /*
   * Google reCaptcha
   */
  function SetCaptchaToken(isFirst) {
    grecaptcha.ready(function() {
      let $action = '';
      if($("#jobs_form").length) {
        $action = 'succesfactor_jobs_submit_form';
      } else if ($("#contact__form").length) {
        $action = 'succesfactor_contact_submit_form';
      }
      grecaptcha.execute('6LekEtIZAAAAAMKm21JcZjdEvobZI-Zg1gYAlmDn', {
        action: $action,
      }).then(function(token) {
        if (isFirst) {
          if($("#jobs_form").length) {
            $('#jobs_form').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '"> <span class="error error-captcha"></span>');
            $('#jobs_form').prepend('<input type="hidden" name="g-action" value="succesfactor_jobs_submit_form">');
          } else if ($("#contact__form").length) {
            $('#contact__form').prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '"> <span class="error error-captcha"></span>');
            $('#contact__form').prepend('<input type="hidden" name="g-action" value="succesfactor_contact_submit_form">');
          }
        } else if (!isFirst) {
          $("input[name=g-recaptcha-response]").val(token);
        }        
      });
    });
  }
  SetCaptchaToken(true);
  setInterval( function () {SetCaptchaToken(false); }, 2 * 60 * 1000);
});
