import Swiper from 'swiper';
import { Lazy } from 'swiper'
import 'swiper/css';

Swiper.use( [ Lazy ] );

let LogosSwiper;

const breakpoint = window.matchMedia( '(max-width: 9999px)' );

const breakPointchecker = function() {
  if ( !breakpoint.matches ) {
    if ( LogosSwiper !== undefined ) LogosSwiper.destroy( true, true );
    return;
  } else if ( breakpoint.matches ) {
    return enableLogosSwiper();
  }
}

const enableLogosSwiper = function() {
  LogosSwiper = new Swiper('.tabs__partners__logos', {
    loop: true,
    spaceBetween: 10,
    centeredSlides: true,
    allowSlidePrev: true,
    allowSlideNext: true,
    slideToClickedSlide: true,
    touchRatio: 0.2,
    slidesPerView: 3,
    preloadImages: false,
    lazy: true,
    breakpoints: {
      768: {
        slidesPerView: 4,
      },
      1024: {
        slidesPerView: 6,
        noSwiping: false,
        allowSlidePrev: true,
        allowSlideNext: true,
        centeredSlides: false
      }
    },
  })
}

breakpoint.addEventListener( 'change', () => {
  return breakPointchecker();
} );

breakPointchecker();