/**
 * jQuery Functions 
 */
jQuery(document).ready( function($) {
  $('#showmore').on( 'click', (e) => {
    e.preventDefault();

    var button = $(this),
      data = {
      'action': 'loadmore',
      'query': campagnes_loadmore_params.posts, // that's how we get params from wp_localize_script() function
      'page' : campagnes_loadmore_params.current_page
    };

    $.ajax({ // you can also use $.post here
      url : campagnes_loadmore_params.ajaxurl, // AJAX handler
      data : data,
      type : 'POST',
      beforeSend : function ( xhr ) {
        $('#showmore').text('Artikelen ophalen...'); // change the button text, you can also add a preloader image
      },
      success : function( data ){
        if( data ) { 
          $('#newblogs').append(data);
          $('#showmore').html('<a href="#">Nog meer blogs ophalen</a>'); // change the button text, you can also add a preloader image

          campagnes_loadmore_params.current_page++;

          if ( campagnes_loadmore_params.current_page == campagnes_loadmore_params.max_page ) 
          $('#showmore').remove(); 
        } else {
          $('#showmore').remove();
        }
      }
    });
  });
});