import crumbs from 'crumbsjs';

const notice = document.querySelector('#js-notifier');
const closeBtn = document.querySelector('#close__notifier');
const timeToHide = document.querySelector('#js-notifier-time-to-hide') ? document.querySelector('#js-notifier-time-to-hide').value : '';
const isDebugging = document.querySelector('#js-notifier-debugger') ? document.querySelector('#js-notifier-debugger').value : false; 
const cookieCk =  crumbs.get('notifier');
const cookieLs =  crumbs.ls.get('notifier');

window.addEventListener( 'load', (e) => {
  if (document.body.contains(notice)) {
    if (isDebugging) {
      return false;
    } else {
      if (cookieCk !== null) { hasCookies('cookie'); }
      else if (cookieLs !== null) { hasCookies('localStorage'); }
      else { setCookie('all'); }
    }
  }
});

if (document.body.contains(notice)) {
  closeBtn.addEventListener('click', (e) => {
    deleteNotice(true)
  });
}

function hasCookies(type) {
  let hasCookie = false;
  const current = new Date();

  if (type === 'cookie') {
    if (current < Date.parse(cookieCk)) {
      hasCookie = true;
      deleteNotice(false);
    } else {
      hasCookie = false;
      setCookie('cookie');
    }
  } 
  else if (type === 'localStorage') {
    if (current < Date.parse(cookieLs)) {
      hasCookie = true;
      deleteNotice(false);
    } else {
      hasCookie = false;
      setCookie('localStorage');
    }
  }

  return hasCookie;
}

function setCookie(type) {
  const current = new Date();
  var dateMillis = current.getTime();
  var timePeriod = timeToHide; 
  let parts = timePeriod.split(/:/);
  let timePeriodMillis = (parseInt(parts[0], 10) * 60 * 60 * 1000) + (parseInt(parts[1], 10) * 60 * 1000) + (parseInt(parts[2], 10) * 1000);
  let newDate = new Date();
  newDate.setTime(dateMillis + timePeriodMillis);
  return (type === 'localStorage') ? crumbs.ls.set("notifier", newDate) : (type === 'all') ? crumbs.ls.set("notifier", newDate) && crumbs.set("notifier", newDate) : crumbs.set("notifier", newDate);
}

function deleteNotice(wasVisible) {
  if (wasVisible) {
    notice.classList.add('notice__animateClose');
    setTimeout( () => {
      return notice.remove();
    }, 15000)
  } else {
    return notice.remove();
  }
}