// import Swiper JS
import Swiper, { Navigation, Pagination, Mousewheel, Controller} from 'swiper';
import 'swiper/css';
import "swiper/css/pagination";

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Mousewheel, Controller]);

let whatifthumbs = new Swiper(".steljevoor-nav-swiper", {
  init: false,
  direction: "horizontal",
//  autoHeight: true,
  centeredSlides: true,
  slidesPerView: 'auto',
  mousewheel: false,
  touchRatio: .2,
  observeParents: true,
  watchSlidesVisibility: true,
  watchSlidesProgress: true,
  touchRatio: 0.2,
  allowSlidePrev: true,
  allowSlideNext: true,
  slideToClickedSlide: true,
  breakpoints: {
  768: {
    direction: "vertical"
  },
  1024: {
    direction: "vertical"
  }
  }
})

let whatifswiper = new Swiper(".steljevoor-swiper", {
  init: false,
  direction: "horizontal",
  autoHeight: true,
  slidesPerView: 'auto',
  mousewheel: true,
  pagination: {
    el: ".steljevoor-pagination",
    type: 'fraction'
  },
  navigation: {
    nextEl: ".steljevoor-button-next",
    prevEl: ".steljevoor-button-prev"
  },
  watchSlidesProgress: true,
  breakpoints: {
    768: {
      direction: "vertical"
    },
    1024: {
      direction: "vertical"
    }
  }
});

whatifthumbs.on ('init', () => {
whatifthumbs.controller.control = whatifswiper;
});

whatifswiper.on ('init', () => {
whatifswiper.controller.control = whatifthumbs;
});

whatifswiper.init();
whatifthumbs.init();

const goToLinks = document.querySelectorAll('.steljevoor-cta');
const desktopBgs = document.querySelectorAll('.steljevoor-bg-desktop');
const navigation = document.querySelector('.steljevoor-navigation');
const sideNavigation = document.querySelector('.steljevoor-nav-swiper');

goToLinks.forEach( (link) => {
link.addEventListener('click', (e) => {
  e.preventDefault();

  window.scrollTo({ top: 0, behavior: 'smooth' });
  if (sideNavigation) {
    sideNavigation.classList.add('hide');
  }
  desktopBgs.forEach( (bg) => {
    bg.classList.add('wide');
  })
  navigation.style.opacity = '0';
  setTimeout( () => {
    window.location.href = e.target.href;
  }, 300);
})
})

const scrollMoreLink = document.querySelector('.scrolltomore');
if (scrollMoreLink) {

scrollMoreLink.addEventListener('click', (e) => {
  
  setTimeout( () => {
    scrollMoreLink.remove();
  }, 3000);
  
});
}

const contentSwiper = document.querySelector('.steljevoor-content-swiper');
if (contentSwiper) {
let contentSwipeSwiper = new Swiper(".steljevoor-content-swiper", {
  slidesPerView: 1,
  autoHeight: true,
  pagination: {
    el: ".steljevoor-content-pagination",
    type: "bullets"
  },
  navigation: {
    nextEl: ".steljevoor-content-button-next",
    prevEl: ".steljevoor-content-button-prev"
  },
});
}

const relatedSwiper = document.querySelector('.steljevoor-related-swiper');
if (relatedSwiper) {
let relatedSwiper = new Swiper(".steljevoor-related-swiper", {
  slidesPerView: 1.1,
  spaceBetween: 16,
  breakpoints: {
    768: {
      slidesPerView: 2,
      spaceBetween: 32
    },
    1200: {
      slidesPerView: 3,
      spaceBetween: 48
    }
  },
  pagination: {
    el: ".steljevoor-related-pagination",
    type: "bullets"
  },
  navigation: {
    nextEl: ".steljevoor-related-button-next",
    prevEl: ".steljevoor-related-button-prev"
  },
});
}