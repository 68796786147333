function setCookieDefault(cname, cvalue) {
   const date = new Date();
   date.setFullYear(date.getFullYear() + 1);
   const expires = "expires="+ date.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + "; path=/;";
}

function getCookieDefault(cname) {
   const value = `; ${document.cookie}`;
   const parts = value.split(`; ${cname}=`);
   if (parts.length == 2) return parts.pop().split(';').shift();
}

if(window.loadGtm && ( (getCookieDefault('cconsent') === "true" && getCookieDefault('cconsent') !== "false") || (getCookieDefault('cconsent') === true && getCookieDefault('cconsent') !== false) )) {
   window.loadGtm(window.trackingCodeGtm);
}

window.cconsent = function(isAccepted) {
   if (isAccepted && window.loadGtm) {
      window.loadGtm(window.trackingCodeGtm);
   }

   setCookieDefault('cconsent', isAccepted);
};

const removeCookieBar = (remove = true) => {
   const element = document.getElementById('cookie_consent');
   if(remove) {
      element.style.display = 'none';
   } else {
      element.style.display = 'flex';
   }
};

const refreshCookieConsent = () => {
   cconsent(false);
   document.cookie = "cconsent=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

window.cookieBar = function(isAccepted) {
   setCookieDefault('cconsent', isAccepted);

   removeCookieBar();

   if(isAccepted === "false") {
      refreshCookieConsent();
   }
};

if(getCookieDefault('cconsent') === "true" || getCookieDefault('cconsent') === true) {
   cconsent(true);
   removeCookieBar();
} else if (getCookieDefault('cconsent') === "false" || getCookieDefault('cconsent') === false) {
   removeCookieBar();
   refreshCookieConsent();
} else {

   cconsent(true);
   removeCookieBar(false);
}
