$(function () {

  'use strict';

  /**
   * Ensure run scripts only when contact form 7 is available and their AJAX enable
   */
  if (typeof wpcf7 !== 'undefined' && wpcf7 !== null && wpcf7) {


    // TODO: Which form is currently submitting? 
    // check this.

    window.oldFormData = window.FormData;

    /**
     * Set eigen bijdrage conditional logic
     */
    $('body').on('change', 'select[name="training-naam"]', function (e) {

      if ($(':input[type="submit"]').hasClass('btn-disabled')) {
        $('.wpcf7-response-output').hide();
        $(':input[type="submit"]').removeClass('btn-disabled').attr('value', 'Verzenden');
      }

      let groupEigenBijdrage = document.querySelector('div[data-id="eigen-bijdrage-check-group"]');
      if (groupEigenBijdrage.classList.contains('wpcf7cf-hidden') || $('div[data-id="eigen-bijdrage-check-group"]').css('display') === 'block') {
        if ($('input[name="accepteert-eigen-bijdrage[]"]').prop('checked') === true) {
          $('input[name="accepteert-eigen-bijdrage[]"]').prop('checked', false);
        } else {
          $('input[name="accepteert-eigen-bijdrage[]"]').prop('checked', true);
        }
      } else {
        $('input[name="accepteert-eigen-bijdrage[]"]').prop('checked', true);
      }
      if (groupEigenBijdrage.classList.contains('wpcf7cf-hidden')) {
        $('input[name="accepteert-eigen-bijdrage[]"]').prop('checked', true);
      }
    });

    /**
     * If event comes here that means CF7 doesn't submit form via ajax so we need to prevent form being submitted
     */

    $('body').on('click', '.wpcf7-form input[type="submit"]', function (e) {
      let formIsValid = true;
      $(':input[type="submit"]').addClass('btn-disabled').attr('value', "Bezig...");
      var $form = $(this.form);

      // if (typeof window.FormData === 'function' || typeof window.FormData === 'boolean') {
      //   e.preventDefault();
      // }

      $form.find('input, select, textarea').each(function (index, element) {
        if ($(element).hasClass('wpcf7-validates-as-required') && $(element).attr('type') !== 'date') {
          if (element.value === '') {
            $(element).addClass('is-invalid').attr('aria-invalid', 'true');
            formIsValid = false;
          } else if (element.value === 'Selecteer een training') {
            $(element).addClass('is-invalid').attr('aria-invalid', 'true');
            formIsValid = false;
          } else {
            $(element).removeClass('is-invalid');
            $(element).addClass('is-valid').attr('aria-invalid', 'false');
          }
        } else if ($(element).attr('type') === 'date') {
          formIsValid = validateDateField(element) && formIsValid;
        }
      });



      if (!formIsValid) {
        $(':input[type="submit"]').removeClass('btn-disabled').attr('value', 'Verzenden');
        e.preventDefault();
      }

    });

    var wpcf7Elements = document.querySelectorAll('.wpcf7');

    if (wpcf7Elements) {

      [...wpcf7Elements].forEach((wpcf7Elm) => {

        /**
         * Set eigen bijdrage to true if is Single Page and is invisible
         */
        // toggleAttribute('checked')
        let singlePage = document.querySelector('.single-academy-wrapper');
        if (singlePage) {

          let groupEigenBijdrage = document.querySelector('div[data-id="eigen-bijdrage-check-group"]');
          let acceptsEigenBijdrage = wpcf7Elm.querySelector('input[name="accepteert-eigen-bijdrage[]"');

          if (groupEigenBijdrage && acceptsEigenBijdrage) {

            if (groupEigenBijdrage.classList.contains('wpcf7cf-hidden') || $('div[data-id="eigen-bijdrage-check-group"]').css('display') === 'block') {

              if (acceptsEigenBijdrage.checked) {
                acceptsEigenBijdrage.toggleAttribute('checked');
              } else {
                acceptsEigenBijdrage.toggleAttribute('checked');
              }
            } else {
              acceptsEigenBijdrage.toggleAttribute('checked');
            }
          }

        }
        /**
         * Set flag before submit form
         */
        wpcf7Elm.addEventListener('wpcf7beforesubmit', function (e) {
          window.FormData = true;
          e.preventDefault();

        });

        /**
         * Revert flag after form submitted
         */
        wpcf7Elm.addEventListener('wpcf7submit', function (e) {
          window.FormData = window.oldFormData;
          $(':input[type="submit"]').removeClass('btn-disabled').attr('value', 'Verzenden');

        });


        wpcf7Elm.addEventListener('wpcf7invalid', function (event) {
          $(':input[type="submit"]').attr('value', "Verzenden");
          $(':input[type="submit"]').removeClass('btn-disabled');
        });
      })

    }
  }

  /**
   * Refresh invalid state
   */
  $(document).on('click focus', 'input, select, textarea', function () {
    if ($(this).hasClass('is-invalid')) $(this).removeClass('is-invalid').attr('aria-invalid', 'false');
    if ($(this).hasClass('wpcf7-not-valid')) $(this).removeClass('wpcf7-not-valid').attr('aria-invalid', 'false');
  });

  function validateDateField(element) {
    var fieldValue = element.value;
    var minDate = element.min;
    var maxDate = element.max;
    var formIsValid = true;
  
    if (element.classList.contains('wpcf7-validates-as-required')) {
      if (fieldValue === '') {
        element.classList.add('is-invalid');
        element.setAttribute('aria-invalid', 'true');
        formIsValid = false;
      } else if (minDate && fieldValue < minDate) {
        element.classList.add('is-invalid');
        element.setAttribute('aria-invalid', 'true');
        formIsValid = false;
      } else if (maxDate && fieldValue > maxDate) {
        element.classList.add('is-invalid');
        element.setAttribute('aria-invalid', 'true');
        formIsValid = false;
      } else {
        element.classList.remove('is-invalid');
        element.classList.add('is-valid');
        element.setAttribute('aria-invalid', 'false');
      }
    }
  
    return formIsValid;
  }


});